import React from "react"
import { Link } from "gatsby"
import { EqualHeight, EqualHeightElement } from "react-equal-height"

import t from "../locale"

const GlobalTiles = ({ lang }) => {
  const isBrowser = typeof window !== "undefined"

  return (
    <section className="global-section-tiles global__padd">
      {isBrowser && (
        <div className="row">
          <EqualHeight>
            <div className="col-md-4">
              <div className="global-section-tiles__tile">
                <EqualHeightElement name="Desc">
                  <Link
                    to={t("/offices/", lang)}
                    className="global-section-tiles__tile-inner"
                  >
                    <h3>{t("Offices", lang)}</h3>
                    <p>
                      {t(
                        "Businesses are discovering the benefits of the base in Krakow's highest most desirable building.",
                        lang
                      )}
                    </p>
                  </Link>
                </EqualHeightElement>
                <div className="img__wrapper pic">
                  <img
                    src={require("../assets/images/offices-tile-image.jpg")}
                    srcSet={require("../assets/images/offices-tile-image@2x.jpg")}
                    alt=""
                  />
                </div>
                <div className="cursors">
                  <div className="cursor"></div>
                  <div className="cursor"></div>
                  <div className="cursor"></div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="global-section-tiles__tile">
                <EqualHeightElement name="Desc">
                  <Link
                    to={t("/retail/", lang)}
                    className="global-section-tiles__tile-inner"
                  >
                    <h3>{t("Retail", lang)}</h3>
                    <p>
                      {t(
                        "Retail is at last rebalancing in favour of human experience. Learn more how to be part of it.",
                        lang
                      )}
                    </p>
                  </Link>
                </EqualHeightElement>
                <div className="img__wrapper">
                  <img
                    src={require("../assets/images/retail-tile-image.jpg")}
                    srcSet={require("../assets/images/retail-tile-image@2x.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="global-section-tiles__tile">
                <EqualHeightElement name="Desc">
                  <Link
                    to={t("/unity-eye/", lang)}
                    className="global-section-tiles__tile-inner"
                  >
                    <h3>{t("UNITY Eye", lang)}</h3>
                    <p>
                      {t(
                        "Krakow’s highest and best view offers visitors breathtaking panoramas of the city.",
                        lang
                      )}
                    </p>
                    <div className="global-section-tiles__tile-soon">
                      <span>{t("Wkrótce otwarcie…", lang)}</span>
                    </div>
                  </Link>
                </EqualHeightElement>
                <div className="img__wrapper">
                  <img
                    src={require("../assets/images/unity-eye-tile-image.jpg")}
                    srcSet={require("../assets/images/unity-eye-tile-image@2x.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </EqualHeight>
        </div>
      )}
    </section>
  )
}

export default GlobalTiles
